import React from "react"
import get from 'lodash/get'

const Pitch = ({pitch}) => {
  const sectionPitchs = get(pitch, 'SectionPitchs', []) || []
  return (
    <>
      <div className="container md:block lg:py-8">
        {(sectionPitchs || []).map((item, index) => {
          return (
            <div className="mb-6 md:mt-8" key={index}>
              <div className="text-2xl mb-2 font-black text-666">
                {item.title}
              </div>
              <div dangerouslySetInnerHTML={{
                  __html: `${item.content}`,
                }}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Pitch