import React, { useState } from "react";
import Button from "../elements/button"
import { Formik, Form, Field } from "formik"
const SearchForm = ({ data }) => {
    const [loading, setLoading] = useState(false)
    return (
        <>
            <div className="py-10 text-center">
                <h1 className="text-3xl mb-10 font-bold mb-2">Search Form</h1>
                <div className="flex flex-col items-center" >
                    <Formik
                        initialValues={{ query: "" }}
                        // validationSchema={LeadSchema}
                        onSubmit={async (values, { setSubmitting, setErrors }) => {
                            setLoading(true)
                            setLoading(false)
                            setSubmitting(false)
                        }}
                    >
                        {({ errors, touched, isSubmitting }) => (
                            <div style={{ margin: "auto", maxWidth: "1000px"} }>
                                <Form className="flex gap-4" >
                                    <Field
                                        className="text-base focus:outline-none py-4 md:py-0 px-4 border-2 rounded-md md:w-full"
                                        type="query"
                                        name="text"
                                        placeholder={"data.searchPlaceholder"}
                                        style = {{maxWidth: "700px", width:"700px"}}
                                    />
                                    <Button
                                        button={{ text: "Search", color: "secondary" }}
                                        disabled={isSubmitting}
                                        loading={loading}
                                        compact={true}
                                    />
                                </Form>
                                
                            </div>
                        )}
                    </Formik>

                </div>
            </div>

        </>
    )
}
export default SearchForm