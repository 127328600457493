import React from "react"

const Title = ({ title, description }) => {
  if (!title) return null;

  return (
    <>
      <div className="text-center sm:text-left">
        <h1 className="tracking-tight font-extrabold text-gray-900 max-w-2xl text-3xl md:text-5xl block xl:inline">
          {title}
        </h1>
        {
          description ?
            <div dangerouslySetInnerHTML={{ __html: description }} className="mt-1 text-2xl text-gray-500 md:text-2xl font-light sm:mt-5 sm:text-lg md:mt-5">

            </div> : null
        }
      </div>
    </>
  )
}

export default Title