import React from "react"
import PropTypes from "prop-types";

const RichText = ({ data }) => {
  if(!data) return null;
  return (
    <>
    {
      data.title ? 
      <div className="prose prose-lg container py-12">
        <div className="title">{data.title}</div>
      </div>:""
    }
    <div className="prose prose-lg container py-12" dangerouslySetInnerHTML={{ __html: data.content }}>
      
    </div>
    </>
  );
};

RichText.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
  }),
};

export default RichText;
