import React from "react"
import get from 'lodash/get'

const Reviews = ({reviews}) => {
  const sectionReviews = get(reviews, 'SectionReviews', []) || []
  return (
    <>
      {(sectionReviews || []).map((item, index) => {
        const sectionReviewsUrl = get(item, 'avatar.url', '') || ''
        return (
          <div className="container lg:pt-4 lg:pb-12" key={index}>
            <div className="mx-4">
              <div className="w-full inline-block mt-3">
                <div className="mb-4 flex items-center">
                  <div className="w-16 h-16 inline-flex">
                    <img src={`${process.env.GATSBY_STRAPI_URL}${sectionReviewsUrl}`} />
                  </div>
                  <div className="block mt-4 lg:ml-6">
                    <h3 className="text-xl font-extrabold">{item.name}</h3>
                    <p className="text-base">{item.role}</p>
                  </div>
                  <span className="ml-6 block font-bold text-3xl text-right lg:hidden">{item.valueInvest}</span>
                </div>
              </div>
              <div className="flex">
                <div className="mr-6 w-full lg:w-2/3" 
                  dangerouslySetInnerHTML={{
                    __html: `${item.content}`,
                  }}
                />
                <div className="ml-6 hidden lg:inline-flex items-center justify-center lg:w-1/3">
                  <span className="font-bold text-3xl text-right">{item.valueInvest}</span>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default Reviews