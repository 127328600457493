import React, { useEffect, useState } from "react"
import { fetchAPI } from "@/utils/api"
import Navbar from "../projects/index/navbar"
import Title from "../projects/index/title"
import Results from "../projects/index/results"
// import NavbarSearch from "../projects/index/navbar-search"

const FeatureProjectsList = ({ data }) => {
  
  const { pathDetail, title, description } = data;

  const [projectsData, setProjectsData] = useState([])
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState()

  useEffect(() => {
    const getProjectsData = async (config) => {
      const { locale, path, pathCategory, limit, sort } = config;
      const result = await fetchAPI(`${path}?_locale=${locale}&_limit=${limit}&_sort=${sort}`)
      if (pathCategory) {
        const cats = await fetchAPI(`${pathCategory}&_locale=${locale}`)
        setCategories(cats)
      }
      setProjectsData(result)
    }
    getProjectsData(data)
  }, []);

  return (
    <>
      <div className="container mt-10 sm:mt-12 md:mt-16 xl:mb-0">
        <Title title={title} description={description} />
        <Navbar categories={categories} category={category} setCategory={setCategory} />
        {/* <NavbarSearch categories={categories} setCategory={setCategory} /> */}
        <Results path={pathDetail} projectsData={projectsData} category={category} />
      </div>
    </>
  )

}

export default FeatureProjectsList

