import React from "react"
import { FaFireAlt } from "@react-icons/all-files/fa/FaFireAlt";

const Trending = () => {
  return (
    <>
      <span className="text-red-500 bg-white rounded-md mb-1.5 mr-2 relative border-red-500" 
        style={{padding:"7px 9px 7px 26px", border: "1px solid" }}>
          <FaFireAlt className="text-lg left-2 absolute top-1/5 -translate-y-1/2"/>Trending
      </span>
    </>
  )
}

export default Trending