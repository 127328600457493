import React from "react"
import get from 'lodash/get'

import Trending from "../../tags/trending"
import SoldOut from "../../tags/sold-out"
import Image from "../../image"
import _ from "lodash"

const ResultsItem = ({ item }) => {
  const categories = get(item, 'categories', []) || []
  return (
    <>
      <div className="relative md:mr-6 mb-3" style={{ minHeight: "400px", border: "1px solid #eee" }}>
        <div className="left-1 absolute top-1 z-5">
          <div className="flex font-black relative text-xs uppercase">
            <Trending />
            <SoldOut />
          </div>
        </div>
        <Image media={item.avatar} style={{ height: "255px" }} />
        <div className="bg-white rounded-b-md font-light relative h-48">
          <div className="absolute left-8 -top-20 w-16">
            <Image media={item.icon} />
          </div>
          <div className="mx-4">
            <p className="text-2xl font-extrabold leading-5 mb-2 mt-10">
              {item.name}
            </p>
            <p className="text-gray-600 text-lg mb-6" style={{ fontSize: "14px" }}>
              {_.get(item, 'summary', '').substring(0, 100)}...
            </p>
            {/* <div className="mt-8 text-lg hidden">Kansas City, MO</div> */}
            {(categories || []).map((itemCategories, indexCategories) => {
              return (
                <div className="mt-4 text-base h-5 inline mr-2 rounded-lg bg-eee" key={indexCategories}
                  style={{ padding: "4px 6px 5px" }}> {itemCategories.name}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default ResultsItem