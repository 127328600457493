import React, { useState } from "react"
import Button from "../elements/button"
import * as yup from "yup"
import { Formik, Form, Field } from "formik"
import { fetchAPI } from "@/utils/api"


const SignUpForm = ({ data }) => {

    const [loading, setLoading] = useState(false)
    const [isShowModal, setIsShowModal] = useState(false)
    const SignUpSchema = yup.object().shape({
        email: yup.string().email(data.noteEmail).required(data.notErrEmailEmpty),
        fullName: yup.string().min(5, data.noteFullName).max(50, data.noteFullName).required(data.notiErrFullNameEmpty),
        contact: yup.string().matches(/(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/, data.notePhone).required(data.notiErrContactEmpty),
    })
    return (
        <>
            {isShowModal ? (
                <div class="text-center py-3 px-5 mb-4 bg-green-100 text-green-900 text-sm rounded-md border border-green-200" role="alert">
                    <strong>Bạn đã đăng kí thành công</strong>
                </div>
            ) : null}

            <div className="py-10 text-center">
                <h1 className="text-3xl mb-10 font-bold mb-2">{data.title}</h1>
                <div className="items-center" style={{ margin: "auto", maxWidth: "400px" }}  >
                    <Formik
                        initialValues={{ email: "", "fullName": "", contact: "" }}
                        validationSchema={SignUpSchema}
                        onSubmit={async (values, { setSubmitting, setErrors }) => {
                            setLoading(true)
                            try {
                                setErrors({ api: null })
                                await fetchAPI("/lead-form-submissions", {
                                    method: "POST",
                                    body: JSON.stringify({
                                        email: values.email,
                                        firstName: values.fullName,
                                        password: values.contact,
                                    }),
                                })
                            } catch (err) {
                                setErrors({ api: err.message })
                            }
                            setIsShowModal(true)

                            setLoading(false)
                            setSubmitting(false)
                            setIsShowModal(true)
                        }}
                    >
                        {({ errors, touched, isSubmitting }) => (
                            <>
                                <Form className="col flex flex-col md:flex-col gap-4 ">
                                    <Field
                                        className="text-base focus:outline-none py-4 px-4 border-2  h-18"
                                        type="text"
                                        name="fullName"
                                        placeholder={data.fullnamePlaceholder}
                                    />
                                    {
                                        errors.fullName ? (
                                            <p className="text-red-500 h-4 text-sm mt-1 ml-2 text-left">
                                                {errors.fullName}
                                            </p>
                                        ) : null
                                    }
                                    <Field
                                        className="text-base focus:outline-none py-4 px-4 border-2  h-18"
                                        type="email"
                                        name="email"
                                        placeholder={data.emailPlaceholder}
                                    />
                                    {
                                        errors.email ? (
                                            <p className="text-red-500 h-4 text-sm mt-1 ml-2 text-left">
                                                {errors.email}
                                            </p>
                                        ) : null
                                    }
                                    <Field
                                        className="text-base focus:outline-none py-4 px-4 border-2 h-18"
                                        type="text"
                                        name="contact"
                                        placeholder={data.contactPlaceholder}
                                    />
                                    {
                                        errors.contact ? (
                                            <p className="text-red-500 h-4 text-sm mt-1 ml-2 text-left">
                                                {errors.contact}
                                            </p>
                                        ) : null
                                    }
                                    <Button
                                        button={data.submitButton}
                                        disabled={isSubmitting}
                                        loading={loading}
                                    />
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>


            </div>
        </>

    )
}
export default SignUpForm