import React from "react"

const Video = ({}) => {
  return (
    <>
      <iframe className="w-full h-64 md:h-80 lg:h-96" src="https://www.youtube.com/embed/Ss6i4Afydck" 
        title="YouTube video player" frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      >
      </iframe>
    </>
  )
}

export default Video