import React from "react"
import { Formik, Form, Field } from "formik"
import HeaderButton from "./header-button";

const Tabs = ({ title }) => {
  const projectTabs = [title.pitch, title.discussion, title.updates, title.reviews ]
  console.log(projectTabs, "projectTabs");
  console.log(title, "title");
  return (
    <>
      <div className="inline-flex py-3 px-4 md:hidden w-full">
        {/* <select className="bg-eee w-full mr-4 pl-3 focus:outline-none">
          <option defaultValue=''>(*)</option>
          <option value="#">{title.pitch}</option>
          <option value="#">{title.discussion}</option>
          <option value="#">{title.updates}</option>
          <option value="#">{title.reviews}</option>
        </select> */}
        <Formik>
          <div className="w-full mr-4 pl-3">
            <Form className="flex gap-4">
              <Field 
                component="select" id={""} className="w-full focus:outline-none" name={""}>
                <option defaultValue=''>(*)</option>
                {projectTabs.map((item, index) => 
                  <option value={index} label={item}>{item}</option>
                )}
              </Field>
            </Form>
          </div>
        </Formik>
        <div className="block md:hidden">
          <HeaderButton />
        </div>
      </div>
      <div className="container hidden md:inline-flex md:mb-10">
        <div className="bg-white h-3 w-100 xl:px-8">
          <div className="inline-flex relative text-base align-top">
            <div className="my-7 h-12 mr-3 shadow-tab hover:shadow-tabhover">
              <a className="text-b3b3b3 text-lg font-semibold hover:text-linkmodule">{title.pitch}</a>
            </div>
            <div className="my-7 h-12 mr-3 shadow-tab hover:shadow-tabhover">
              <a className="text-b3b3b3 text-lg font-semibold mr-2 hover:text-linkmodule">{title.discussion}</a>
              <span className="rounded bg-eee text-6a6a6a" style={{ padding: "1px 6px 3px" }}>#</span>
            </div>
            <div className="my-7 h-12 mr-3 shadow-tab hover:shadow-tabhover">
              <a className="text-b3b3b3 text-lg font-semibold mr-2 hover:text-linkmodule">{title.updates}</a>
              <span className="rounded bg-eee text-6a6a6a" style={{ padding: "1px 6px 3px" }}>#</span>
            </div>
            <div className="my-7 h-12 mr-3 shadow-tab hover:shadow-tabhover">
              <a className="text-b3b3b3 text-lg font-semibold mr-2 hover:text-linkmodule">{title.reviews}</a>
              <span className="rounded bg-eee text-6a6a6a" style={{ padding: "1px 6px 3px" }}>#</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Tabs