import React from "react"
import SoldOut from "../../tags/sold-out"
import { BiDotsVerticalRounded } from "@react-icons/all-files/bi/BiDotsVerticalRounded";

const Invest = ({info}) => {
  return (
    <>
      <div className="flex flex-col">
        <div className="mt-8 md:mt-0">
          <div className="inline-block align-top mb-3 lg:mb-5">
            <SoldOut />
            <div className="mt-3 mb-2 text-4xl font-black md:text-2xl lg:text-4xl text-19c157"> 
              {info.valuationCap}
            </div>
            <div className="text-lg font-light lg:text-xl text-777"> 
              {info.description}
            </div>
            <div className="mt-2 h-2 rounded w-full bg-19c157" />
          </div>
          <div className="w-1/2 inline-block md:w-full">
            <div className="md:mb-2.5 md:pb-2">
              <div className="text-4xl mb-1 font-black text-black md:text-2xl lg:text-4xl">
                {info.investors}
              </div>
              <p className="text-xl font-light md:text-lg text-777">
                Investors
              </p>
            </div>
          </div>
          <div className="w-1/2 inline-block md:w-full">
            <div className="md:mb-2.5 md:pb-2">
              <div className="text-4xl mb-1 font-black text-black md:text-2xl lg:text-4xl">
                {info.raised}
              </div>
              <p className="text-xl font-light md:text-lg text-777">
                Left to invest
              </p>
            </div>
          </div>
        </div>
        <div className="mt-4 md:mt-0 text-center">
          <div className="text-lg text-white md:text-sm lg:text-lg font-semibold">
            <a style={{padding: "9.5px 30px 12.5px"}}
              className="bg-linkmodule rounded-md inline-block w-full" href="#"
            >
              Join the Waitlist
            </a>
          </div>
          <div className="font-light mt-2 text-xl md:text-sm lg:text-base text-777">
            <strong>{info.minInvestment} </strong>minimum investment
          </div>
        </div>
      </div>
    </>
  )
}

export default Invest