import React from "react"
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";


const SoldOut = () => {
  return (
    <>
      <span className="text-white bg-soldout rounded-md mb-1.5 mr-2 relative border-soldout" 
        style={{padding:"7px 9px 7px 26px", border: "1px solid"}}>
        <FaCheck className="text-lg absolute top-1/4 -translate-y-1/2 left-1" />Sold out
      </span>
    </>
  )
}

export default SoldOut