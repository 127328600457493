import React from "react"
import _ from 'lodash'

const HeaderTitle = ({ projectDetail }) => {
  const projectDetailUrl = _.get(projectDetail, 'icon.url', '') || ''
  const categories = _.get(projectDetail, 'categories', []) || []
  const name = _.get(projectDetail, 'name', "") || ""

  return (
    <>
      <div className="relative z-10">
        <div className="flex">
          <div className="w-10 h-10 rounded lg:w-11 lg:h-11 mr-2">
            <img className="rounded" src={`${process.env.GATSBY_STRAPI_URL}${projectDetailUrl}`} />
          </div>
          <div className="flex items-center text-black font-black">
            <h1 className="text-4xl -mb-1.5 md:-mb-3 lg:-mb-3 lg:text-40px xl:-mb-1 xl:text-5xl">
              {_.get(projectDetail, 'name', "")}
            </h1>
          </div>
        </div>
        <p className="mt-3 text-lg font-light lg:text-xl text-777">
          {name}
        </p>
        <div className="mt-4">
          {(categories || []).map((itemCategories, indexCategories) => {
            return (
              <div className="mt-4 text-base h-5 inline mr-2 rounded-lg bg-eee" key={indexCategories}
                style={{ padding: "4px 6px 5px"}}> {itemCategories.name}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default HeaderTitle