import React from "react"
import get from 'lodash/get'
import moment from 'moment'
import { AiOutlineHeart } from "@react-icons/all-files/ai/AiOutlineHeart";
import { VscComment } from "@react-icons/all-files/vsc/VscComment";
import Image from "../../image"

const Updates = ({updates}) => {
  const sectionUpdates = get(updates, 'SectionUpdates', []) || []
  return (
    <>
      {(sectionUpdates || []).map((item, index) => {
        const time = get(item, 'time', '') || ''
        const date = moment(time).utc().format('MMM') + ' ' + moment(time).utc().format('DD')
        const year = moment(time).utc().format('YYYY')
          return (
            <div className="container md:inline-flex lg:py-4" key={index}>
              <div className="hidden text-left md:inline-block md:w-1/6 md:text-center md:mt-4">
                <div className="text-black font-medium mr-3 md:text-2xl mb-3">{date}</div>
                <div className="text-gray-500 inline-block md:text-lg">{year}</div>
              </div>
              <div className="mb-7 rounded block py-6 w-full md:w-5/6" style={{border: "1px solid #e7e7e7"}}>
                <div className="text-left inline-block md:hidden">
                  <div className="text-black font-medium mr-3">{date}</div>
                  <div className="text-gray-500 inline-block">{year}</div>
                </div>
                <div className="text-2xl mb-2 font-extrabold text-black mt-2">
                  {item.title}
                </div>
                <div dangerouslySetInnerHTML={{
                    __html: `${item.content}`,
                  }}
                />
                <div className="my-2 flex md:py-4" style={{borderBottom: "1px solid gray"}}>
                  <div className="w-16 h-16 inline-flex">
                    <Image media={item.avatar} />
                  </div>
                  <div className="block mt-4 ml-5">
                    <h3 className="text-xl font-extrabold">{item.name}</h3>
                    <div className="text-base">{item.role}</div>
                  </div>
                </div>
                <div className="text-lg">
                  <div className="inline-block mr-8 text-f83a5c">
                    <AiOutlineHeart className="inline-block mr-2 -mt-2 text-2xl" />
                    <span>Like </span>
                    <span>(16)</span>
                  </div>
                  <div className="inline-block text-0049ff">
                    <VscComment className="inline-block mr-2 -mt-2 text-2xl" />
                    <span>Comment </span>
                    <span>(16)</span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
    </>
  )
}

export default Updates