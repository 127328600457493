import React, { useEffect, useState } from "react"
import { fetchAPI } from "@/utils/api"
import Image from "../image";
import Video from "../elements/video";
import CustomLink from "../elements/custom-link";
import classNames from "classnames";
import Button from '../elements/button'
import Title from "../news/title"

const FeatureNewsList = ({ data }) => {
  const { locale, path, limit, sort, title, description, pathDetail, buttonDetail, buttonShowMore } = data;
  
  const [dataNews, setDataNews] = useState([]);
  const [start, setStart] = useState(0);
  const [hiddenButton, setHiddenButton] = useState(false);

  const fetchData = async () => {
    const result = await fetchAPI(`${path}?_locale=${locale}&_sort=${sort}&_limit=${limit}&_start=${start}`)
    setHiddenButton(result.length < limit)
    setStart(start + limit)
    setDataNews([...dataNews, ...result])
  }

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <>
      <div className="container mt-10 sm:mt-12 md:mt-16 xl:mb-0">
        <Title title={title} description={description} />

        <div className="container flex flex-col gap-12 py-12">
          {dataNews.map((feature) => (
            <div
              className={classNames(
                // Common classes
                "flex flex-col justify-start md:justify-between md:items-center gap-10",
                
                "lg:flex-row-reverse"
                
              )}
              key={feature.id}
            >
              {/* Text section */}
              <div className="w-full lg:w-6/12 lg:pr-6 text-lg">
              <h3 className="sub-title">{feature.title}</h3>
              <p className="my-6">{feature.summary}</p>
                <div className="text-base leading-6 font-medium">
                  <CustomLink link={{id: feature.id, text: buttonDetail.text, url: pathDetail.replace("*","") + feature.slug}}>
                    <div className="text-blue-600 with-arrow hover:underline">
                      {buttonDetail.text}
                    </div>
                  </CustomLink>
                </div>
               
              </div>
              {/* Media section */}
              <div className="w-full sm:9/12 lg:w-4/12 max-h-full">
                  {/* Images */}
                  {feature.avatar.mime.startsWith("image") && (
                    <Image media={feature.avatar} className="w-full h-auto" />
                  )}
                  {/* Videos */}
                  {feature.avatar.mime.startsWith("video") && (
                    <Video
                      media={feature.avatar}
                      className="w-full h-auto"
                      autoPlay={false}
                      controls={false}
                    />
                  )}
              </div>
            </div>
          ))}
          <div className="text-left mb-3">
            <Button
              button={buttonShowMore}
              hidden={hiddenButton}
              handleClick={fetchData}
            />
          </div>
        </div>
      </div>
    </>
  )

}

export default FeatureNewsList

