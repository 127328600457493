import React from "react"
import get from 'lodash/get'

import { AiOutlineHeart } from "@react-icons/all-files/ai/AiOutlineHeart";
import { BiPencil } from "@react-icons/all-files/bi/BiPencil";


const Discussion = ({discussion}) => {
  const sectionDiscuss = get(discussion, 'SectionDiscuss', []) || []
  return (
    <>
      <div className="container mt-5 md:block md:mb-5 lg:py-8">
        <div className="text-2xl mb-8 font-black text-center text-666">
          Problem 
        </div>
        <div className="rounded" style={{border: "1px solid #eee"}}>
          <div className="m-4">
            {(sectionDiscuss || []).map((item, index) => {
              const sectionDiscussUrl = get(item, 'avatar.url', '') || ''
              return (
                <div className="mt-8 rounded-md shadow-discuss" key={index} style={{border: "1px solid #ddd"}}>
                  <div className="bg-white text-left" style={{padding: "12px 16px 8px"}}>
                    <div className="mb-2" >
                      <div className="h-16 inline-flex">
                        <img src={`${process.env.GATSBY_STRAPI_URL}${sectionDiscussUrl}`} />
                      </div>
                      <div className="mt-4 ml-6 inline-block">
                        <h3 className="text-xl font-extrabold">{item.name}</h3>
                        <a className="inline-block text-0049ff" href="#"> Investor in Linen</a>
                        <p className="inline-block"> . 1 day ago</p>
                      </div>
                      <div className="mr-6 mt-3" dangerouslySetInnerHTML={{
                          __html: `${item.content}`,
                        }}
                      />
                      <div className="mt-3">
                        <div className="inline-block mr-8">
                          <AiOutlineHeart className="inline-block mr-2 -mt-2 text-2xl" />
                          <span>Like </span>
                        </div>
                        <div className="inline-block">
                          <BiPencil className="inline-block mr-2 -mt-2 text-2xl" />
                          <span>Reply </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="my-8 text-center text-white text-lg md:text-sm lg:text-lg font-semibold">
            <a className="w-3/5 rounded-md bg-linkmodule md:w-2/5 lg:w-1/3" href="#" 
              style={{padding: "9.5px 30px 12.5px"}}
            >
              Join the discussion
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Discussion