import React, { useEffect, useState } from "react"
import { fetchAPI } from "@/utils/api"
import { useLocation } from "@reach/router"
import Content from "../../components/projects/detail/content"
// import CoInvestors from "../../components/projects/detail/co-investors"
import Tabs from "../../components/projects/detail/tabs"
import Reviews from "../../components/projects/detail/reviews"
import Updates from "../../components/projects/detail/updates"
import Pitch from "../../components/projects/detail/pitch"
import Discussion from "../../components/projects/detail/discussion"
import _ from "lodash"

const FeatureProjectDetail = ({ data }) => {

  const { locale, path } = data;

  const location = useLocation();
  const pathname = location.pathname;
  const slug = pathname.split('/').slice(-1);

  const [projectDetail, setProjectDetail] = useState('')

  useEffect(() => {
    const getProjectDetail = async () => {
      const result = await fetchAPI(`${path}?_locale=${locale}&_limit=1&slug=${slug}`)
      setProjectDetail(result[0])
    }
    getProjectDetail()
  }, []);

  var pitch = _.get(projectDetail, 'content[0]', {}) || {}
  var discussion = _.get(projectDetail, 'content[1]', {}) || {}
  var updates = _.get(projectDetail, 'content[2]', {}) || {}
  var reviews = _.get(projectDetail, 'content[3]', {}) || {}


  return (
    <>
      {projectDetail &&
        <div>
          <Content projectDetail={projectDetail} />
          {/* <CoInvestors /> */}
          <Tabs title={data} />
          <Pitch pitch={pitch} />
          <Updates updates={updates} />
          <Reviews reviews={reviews} />
          <Discussion discussion={discussion} />
        </div>
      }

    </>
  )
}

export default FeatureProjectDetail

