import React, { useState } from "react"
import { BsStar } from "@react-icons/all-files/bs/BsStar";
import { FiUpload } from "@react-icons/all-files/fi/FiUpload";
import { AiFillFacebook } from "@react-icons/all-files/ai/AiFillFacebook";
import { FaTelegramPlane } from "@react-icons/all-files/fa/FaTelegramPlane";

const HeaderButton = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="justify-end flex">
        <div>
          <BsStar className="text-2xl mr-6 lg:mr-8 text-777" />
        </div>
        <div className="inline-block text-222">
          <button className="inline-block text-2xl" onClick={() => setIsOpen(!isOpen)}>
            <FiUpload />
          </button>
          {isOpen && (
            <div className="absolute right-0">
              <div className="py-2" role="none">
                <a href="#" className="text-gray-700 pl-4 py-2 text-sm block">
                  <AiFillFacebook className="inline-block mr-2" />
                  <span>Facebook</span>
                </a>
                <a href="#" className="text-gray-700 pl-4 py-2 text-sm inline-flex">
                  <FaTelegramPlane className="inline-block mr-2"/>
                  <span>Telegram</span>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default HeaderButton