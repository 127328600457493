import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const SeoHeader = ({ seo, global }) => { 
  const fullSeo = {
    favicon: global.favicon,
    ...global,
    ...seo,
  }

  useEffect(()=>{
      // Returns the cleanup function
      return ()=>{
          const metaTag = document.querySelector(`meta[data-react-helmet="true"]`);
          if (metaTag) {
              metaTag.remove();
          }
      }
  })

  const getMetaTags = () => {
    const tags = []

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: fullSeo.metaTitle,
        },
        {
          name: "twitter:title",
          content: fullSeo.metaTitle,
        }
      )
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      )
    }

    if (fullSeo.shareImage) {
      // const previewModeIsEnabled = process.env.GATSBY_PREVIEW_SECRET && cookies.strapiPreview === process.env.GATSBY_PREVIEW_SECRET
      
      let url = fullSeo.shareImage.localFile?fullSeo.shareImage.localFile.publicURL:fullSeo.shareImage.url;

      const imageUrl = process.env.GATSBY_STRAPI_URL ? url: `http://localhost:8000${url}`

      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      )
    }
    if (fullSeo.article) {
      tags.push({
        property: "og:type",
        content: "article",
      })
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" })

    return tags
  }

  const metaTags = getMetaTags()
  
  return (
    <Helmet
      title={fullSeo.title || fullSeo.metaTitle}
      titleTemplate={`%s | ${fullSeo.metaTitleSuffix}`}
      meta={metaTags}
      link={[
        {
          rel: "icon",
          href: fullSeo.favicon.localFile?fullSeo.favicon.localFile.publicURL:fullSeo.favicon.url,
        },
      ]}
    />
  )
}

SeoHeader.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
}

SeoHeader.defaultProps = {
  title: null,
  image: null,
}

export default SeoHeader
