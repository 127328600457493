import React from "react"
import get from 'lodash/get'

import HeaderButton from "./header-button"
import HeaderTitle from "./header-title"
import Invest from "./invest"
import Video from "./video"

const Content = ({ projectDetail }) => {
  const info = get(projectDetail, 'info', []) || []

  return (
    <>
      <div className="container my-8">
        <div className="w-full inline-block md:w-2/3">
          <HeaderTitle projectDetail={projectDetail} />
        </div>
        <div className="w-full hidden relative justify-end align-top items-center md:w-1/3 md:inline-flex md:min-h-140px">
          <HeaderButton />
        </div>
      </div>
      <div className="container my-8">
        <div className="w-full inline-block md:w-2/3 md:pr-6 lg:pr-10">
          <Video />
        </div>
        <div className="w-full align-top md:w-1/3 md:inline-block lg:pl-10">
          <Invest info={info} />
        </div>
      </div>
    </>
  )
}

export default Content