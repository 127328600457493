import React from "react"
import { Link } from 'gatsby'
import ResultsItem from "./results-item"
import _ from "lodash"

const Results = ({ path, projectsData, category }) => {
  const pathDetail = path ? path + "/" : "";
  let projects = projectsData;

  if(category && !category.all){
    projects = projectsData.filter(p => {
      if(p.categories){
        for(let c of p.categories){
          if(c.name === category.name) return true;
        }
      }
      return false;
    })
  }
  
  return (
    <>
      {/* <h2 className="font-extrabold text-2xl text-gray-500 mx-3.5 lg:-mx-0 mt-10 mb-5">
        Search results
        <span className="ml-2 opacity-20">388</span>
      </h2> */}
      <div style={{ minHeight: "200px" }}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6">
          {(projects || []).map((item, index) => {
            return (
              <Link to={pathDetail + _.get(item, 'slug', '')} key={index}>
                <ResultsItem item={item} />
              </Link>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Results