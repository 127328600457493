import React, { useEffect, useState } from "react"
import { fetchAPI } from "@/utils/api"
import { useLocation } from "@reach/router"
import RichText from "./rich-text"

const FeatureNewsDetail = ({ data }) => {
  const { locale, path } = data;
  const location = useLocation();
  const pathname = location.pathname;
  const slug = pathname.split('/').slice(-1);
  const [pageContent, setPageContent] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const [result] = await fetchAPI(`${path}/?_locale=${locale}&slug=${slug}`);
      setPageContent(result)
    }
    fetchData()
  }, [])


  return (
    <RichText data={pageContent} />
  )
}

export default FeatureNewsDetail

