import React from "react"

const Navbar = ({ categories, category, setCategory }) => {
  if (!categories || !categories.length) {
    return null;
  }
  if (!category) category = categories[0];

  return (
    <>
      <nav className=" mt-4 mb-9 sm:rounded-lg border border-gray-200">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                {
                  categories.map((c, index) => {
                    if (c.name == category.name) {
                      return (
                        <a key={index} className="bg-gray-600 text-white px-3 py-2 rounded-md text-sm font-medium" aria-current="page">{c.name}</a>
                      )
                    } else {
                      return (
                        <a key={index} onClick={() => { setCategory(c) }} className="text-gray-600 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">{c.name}</a>
                      )
                    }
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar